/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ChevronRightIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.11 20.491c-.003-.394.144-.789.443-1.091l7.058-7.162a.384.384 0 0 0-.005-.543l-7.1-7c-.546-.538-.686-1.411-.242-2.037a1.54 1.54 0 0 1 2.344-.215l8.53 8.41a1.54 1.54 0 0 1 .017 2.178l-8.34 8.46c-.576.586-1.529.698-2.156.169a1.535 1.535 0 0 1-.55-1.169Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(ChevronRightIcon);
export default ForwardRef;
